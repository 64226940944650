"use client";
import { shuffle } from "lodash-es";

import { ClickServer } from "shared-lib/clickserver";
import getAllRecipes from "shared-lib/data/graphql/recipes/getAllRecipes";
import * as gtag from "shared-lib/gtag";
import { brand } from "shared-lib/helpers/envFunctions";
import { log } from "shared-lib/helpers/logging/logger";
import {
  TRACKING_EVENTS,
  TRACKING_CATEGORIES,
  logStatsig,
} from "shared-lib/helpers/statsigFunctions";
import {
  getFormattedDateString,
  getIso8601String,
} from "shared-lib/helpers/utilityFunctions";

import { getRecipeImageURL } from "@/lib/helpers/backendFunctions";

const sectionDelimiter = "***";

export enum MetaTagKeys {
  ATD_LAYOUT = "atdlayout",
  VIEWPORT = "viewport",
  TITLE = "title",
  DESCRIPTION = "description",
  FB_APP_ID = "fb:app_id",
  OG_TYPE = "og:type",
  OG_TITLE = "og:title",
  OG_URL = "og:url",
  OG_DESC = "og:description",
  OG_IMG = "og:image",
  OG_IMG_SECURE_URL = "og:image:secure_url",
  OG_IMG_WIDTH = "og:image:width",
  OG_IMG_HEIGHT = "og:image:height",
  TWITTER_CARD = "twitter:card",
  TWITTER_URL = "twitter:url",
  TWITTER_TITLE = "twitter:title",
  TWITTER_DESC = "twitter:description",
  TWITTER_IMG = "twitter:image",
  TWITTER_CREATOR = "twitter:creator",
  OG_SITE_NAME = "og:site_name",
}

export function isSection(item: { value: string }) {
  return (
    item.value.startsWith(sectionDelimiter) &&
    item.value.endsWith(sectionDelimiter)
  );
}

export function removeDelimiter(item: { value: string }) {
  return item.value.substring(
    sectionDelimiter.length,
    item.value.length - sectionDelimiter.length,
  );
}

export function getStepNumber(
  allDirections: { value: string }[],
  index: number,
) {
  let step = 1;
  for (let i = index - 1; i >= 0; i--) {
    if (isSection(allDirections[i])) {
      break;
    } else {
      step++;
    }
  }
  return step;
}

export function dateString(recipe: Recipe) {
  const curRecipe = recipe as Recipe;
  return getFormattedDateString(
    new Date(
      (curRecipe.sys.createdAt
        ? curRecipe.sys.createdAt
        : curRecipe.sys.publishedAt) as string,
    ),
  );
}

type Content = {
  content?: Content[];
  nodeType: string;
  value?: string;
  marks: [];
  data: any;
};

// TODO: Improve handling of paragraphs vs other nodes,
// potentially only flattening first paragraph for simple descriptions
export function getPlaintextDescription(recipe: Recipe | RecipePromo) {
  // Flatten all content chunks
  // into a single string of all their values combined
  const getContentSubstring = (block: Content): string => {
    if (block?.content) {
      return (
        block.content
          .map((c: Content) => {
            return getContentSubstring(c);
          })
          .join("") + " "
      );
    }

    return block?.value ?? "";
  };

  const content = recipe.richDescription?.json as Content;
  return getContentSubstring(content).trim();
}

export const buildRichResultsSchema = (recipe: Recipe) => {
  const script = document.createElement("script");
  script.setAttribute("type", "application/ld+json");
  let structuredData = {};
  recipe = recipe as Recipe;
  if (recipe) {
    structuredData = {
      "@context": "http://schema.org/",
      "@type": "Recipe",
      name: recipe.title,
      image: [getRecipeImageURL(recipe, 400, 300)],
      author: {
        "@type": "Person",
        name: brand.name,
      },
      datePublished: recipe.sys.publishedAt,
      description: getPlaintextDescription(recipe),
      prepTime: getIso8601String(recipe.time ?? ""),
      cookTime: getIso8601String(recipe.time ?? ""),
      totalTime: getIso8601String(recipe.time ?? ""),
      recipeYield: isNaN(parseInt(recipe.yield ?? ""))
        ? recipe.yield
        : parseInt(recipe.yield ?? ""),
      recipeCategory: recipe.category?.name,
      recipeCuisine: "",
      keywords: recipe.tags?.items.map((t) => t.name).join(", "),
      nutrition: {
        "@type": "NutritionInformation",
        calories: "",
      },
      recipeIngredient: recipe.ingredients?.map((i) => i.value),
      recipeInstructions: recipe.directions?.map((d) => d.value),
      video: [],
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: "5",
        ratingCount: "1",
      },
      review: {
        "@type": "Review",
        reviewRating: {
          "@type": "Rating",
          ratingValue: "5",
          bestRating: "1",
        },
        author: {
          "@type": "Person",
          name: brand.name,
        },
        publisher: brand.name,
        reviewBody: `This ${recipe.title} is delicious!`,
        datePublished: recipe.sys.publishedAt,
      },
    };

    script.textContent = JSON.stringify(structuredData);
    const prevScript = document.querySelector(
      "script[type='application/ld+json']",
    );
    prevScript?.remove();
    document.head.appendChild(script);
  }
};

export const getChicorySchema = (recipe: Recipe | undefined): string => {
  let structuredData = {};
  if (recipe) {
    // const { fields, sys } = recipe as Recipe;
    structuredData = {
      "@context": "https://schema.org/",
      "@type": "Recipe",
      name: recipe.title,
      image: [getRecipeImageURL(recipe, 400, 300)],
      author: {
        "@type": "Person",
        name: brand.name,
      },
      datePublished: recipe.sys.publishedAt,
      description: getPlaintextDescription(recipe),
      prepTime: getIso8601String(recipe.time ?? ""),
      cookTime: getIso8601String(recipe.time ?? ""),
      totalTime: getIso8601String(recipe.time ?? ""),
      recipeYield: isNaN(parseInt(recipe.yield ?? ""))
        ? recipe.yield
        : parseInt(recipe.yield ?? ""),
      recipeCategory: recipe.category?.name,
      recipeCuisine: "",
      keywords: recipe.tags?.items.map((tag) => tag.name).join(", "),
      nutrition: {
        "@type": "NutritionInformation",
        calories: "",
      },
      recipeIngredient: recipe.ingredients?.map((i) => i.value),
      recipeInstructions: recipe.directions?.map((d) => d.value),
      video: [],
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: "5",
        ratingCount: "1",
      },
      review: {
        "@type": "Review",
        reviewRating: {
          "@type": "Rating",
          ratingValue: "5",
          bestRating: "1",
        },
        author: {
          "@type": "Person",
          name: brand.name,
        },
        publisher: brand.name,
        reviewBody: `This ${recipe.title} is delicious!`,
        datePublished: recipe.sys.publishedAt,
      },
    };
  }
  return JSON.stringify(structuredData);
};

export async function getFooterRecipes(mainRecipe: Recipe) {
  const footerRecipeCount = 72;
  const randomOffset = Math.floor(Math.random() * (1000 - footerRecipeCount));

  const response = await getAllRecipes({
    skip: randomOffset,
    limit: footerRecipeCount,
    exclude: [mainRecipe.sys.id],
  });
  const recipes = response.items;
  const pageCount = Math.ceil(response.total / footerRecipeCount);

  return {
    recipes: shuffle(recipes) as Recipe[],
    pageCount,
  };
}

export const navigatorShareAction = async (
  recipe: Recipe,
  shareObject: { title: string; text: string; url: string },
) => {
  if (navigator.share) {
    await navigator
      .share(shareObject)
      .then((result) => {
        log("Successfully shared");
        log({ result: result });
        gtag.event({
          action: TRACKING_EVENTS.sharesheet_success,
          category: TRACKING_CATEGORIES.interaction,
          label: recipe.title,
        });
        logStatsig(TRACKING_EVENTS.sharesheet_success);
        ClickServer.track(TRACKING_EVENTS.sharesheet_success);
      })
      .catch((error) => {
        log("Error sharing");
        log({ error: error });
        if (error.message === "Abort due to cancellation of share.") {
          gtag.event({
            action: TRACKING_EVENTS.sharesheet_abort,
            category: TRACKING_CATEGORIES.interaction,
            label: recipe.title,
          });
          logStatsig(TRACKING_EVENTS.sharesheet_abort);
          ClickServer.track(TRACKING_EVENTS.sharesheet_abort);
          log("Share aborted");
        } else {
          gtag.event({
            action: TRACKING_EVENTS.sharesheet_failure,
            category: TRACKING_CATEGORIES.interaction,
            label: recipe.title,
          });
          logStatsig(TRACKING_EVENTS.sharesheet_failure);
          ClickServer.track(TRACKING_EVENTS.sharesheet_failure);
          log("Share failed");
        }
      });
  }
};
